import React, { Component, useRef } from 'react';
import {Link} from 'react-router-dom';
import ProgramData from '../programs.json';
import PropTypes from 'prop-types';
import {ExecNav} from "./ExecNav";
import { CopyrightBar } from "./CopyrightBar";
import { NavBarWhite } from "./NavBarWhite";
import ModalVideo from 'react-modal-video';
import myVideo from '../img/rosinski-video.mp4';

export class Rosinski extends React.Component {

    constructor () {
        super()
        this.state = {
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
        }
        this.openModal1 = this.openModal1.bind(this);
        this.openModal2 = this.openModal2.bind(this);
        this.openModal3 = this.openModal3.bind(this);
    }

    openModal1 () {
        this.setState({isOpen1: true})
    }
    openModal2 () {
        this.setState({isOpen2: true})
    }
    openModal3 () {
        this.setState({isOpen3: true})
    }

    render () {

        return (

            <div>
            <div id="backgroundImg">
                <section className="main">

                    <NavBarWhite/>

                    <div className="container-fluid fullheight">
                        <div className="row fullheight">

                            <div className="col-lg-9" id="speaker-bg">


                                <div className="speaker-list mt-3">

                                    <div className="row">
                                        <div className="col-md-5 text-center">
                                            <div className="vertical-center">
                                                <img src={require("../img/rosinski233.png")}
                                                     className="img-fluid p-3 w-75"/>
                                                <img src={require("../img/rosinski2.jpg")}
                                                     className="img-fluid p-3 w-75"/>
                                            </div>

                                        </div>


                                        <div className="col-md-7">

                                            <div className="">

                                                <div className="headline pb-5 pt-5 m-0">
                                                    {/* {ProgramData.map((programDetail, index) => {
                                        console.log(programDetail);
                                        return <h1>{programDetail.speaker}</h1>
                                    })} */}

                                                    <h1>{ProgramData[3].speaker}</h1>

                                                    <hr className="hr-headline"/>

                                                </div>

                                                <p className="pb-3">Philippe Rosinski is a world authority in executive coaching and global leadership development,
                                                 author of ground-breaking books: Coaching Across Cultures and Global Coaching. He has pioneered a global approach to 
                                                 coaching that leverages multiple perspectives for greater creativity, impact and meaning. His innovative approach of 
                                                 bringing the crucial intercultural dimension into the practice of coaching has won him worldwide acclaim.</p>

                                                <ul className="program-list">
                                                    <li><a
                                                        href={require("../program-files/rosinski/2013-success-across-cultures.pdf")}
                                                        target="_blank"><h6
                                                        className="mb-4"> {ProgramData[3].programs.first.title}</h6></a>
                                                    </li>
                                                    <li><a href={require("../program-files/rosinski/2017-GLDP.pdf")}
                                                           target="_blank"><h6
                                                        className="mb-4"> {ProgramData[3].programs.second.title}</h6>
                                                    </a></li>
                                                    <li><a href={require("../program-files/rosinski/2018-GLDP.pdf")}
                                                           target="_blank"><h6
                                                        className="mb-4"> {ProgramData[3].programs.third.title}</h6></a>
                                                    </li>
                                                    <li><a
                                                        href={require("../program-files/rosinski/LCAC_Prague_2019.pdf")}
                                                        target="_blank"><h6
                                                        className="mb-4"> {ProgramData[3].programs.fourth.title}</h6><br/>
                                                    </a></li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
<ExecNav/></div>

								<div className="arosinski">
                                <div className="row">
                                        <div className="col-lg-6 txtcenter"> <br/><br/><br/>
                                        <img src={require("../img/txt.png")} 
                                        className="imgrosinski"/>
                                        <br/><br/><br/><br/><br/>
                                        <a href={require("../program-files/rosinski/LCAC_Prague_2020.pdf")} target="_blank"><img src={require("../img/btn.png")} 
                                        className="imgrosinskibtn"/></a>
                                        </div>

                                    <div className="col-lg-6 txtright">
                                        <video controls loop className="brosinski">
                                            <source src={myVideo} type="video/mp4"/>
                                        </video>
                                    </div>

                                </div>
                                </div>

                                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <div className="container-fluid fullheight c">
                                    <div className="row fullheight">

                                    <div className="col-lg-9" id="speaker-bg">

                                    <div className="speaker-list mt-5 pt-5">
                                    <div className="row">
                                        <div className="col-12 video-container mt-5">
                                            <div className="video-list">

                                                <h1>Video Player</h1>

                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen1} videoId='6ZvlY_kSUcI' onClose={() => this.setState({isOpen1: false})} />
                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen2} videoId='UqUFsQav9Ys' onClose={() => this.setState({isOpen2: false})} />
                                                

                                                <div id="carouselExampleControls" className="carousel slide desktop-carousel" data-ride="carousel">
                                                    <div className="carousel-inner">
                                                        <div className="carousel-item active">
                                                            <div className="video-item" onClick={this.openModal1}>
                                                                <img src="https://img.youtube.com/vi/6ZvlY_kSUcI/0.jpg"/>
                                                                <p className="small-text">Philippe Rosinski - Global Coaching</p>
                                                            </div>
                                                            <div className="video-item" onClick={this.openModal2}>
                                                                <img src="https://img.youtube.com/vi/UqUFsQav9Ys/0.jpg"/>
                                                                <p className="small-text">Prof. Philippe Rosinski: How to excel in a complex and culturally diverse world?</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Previous</span>
                                                    </a>
                                                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Next</span>
                                                    </a>

                                                </div>

                                                <div id="carouselExampleControls2" className="carousel slide mobile-carousel" data-ride="carousel">
                                                    <div className="carousel-inner">
                                                        <div className="carousel-item active">
                                                            <div className="video-item" onClick={this.openModal1}>
                                                                <img src="https://img.youtube.com/vi/6ZvlY_kSUcI/0.jpg"/>
                                                                <p className="small-text">Philippe Rosinski - Global Coaching</p>
                                                            </div>
                                                        </div>
                                                        <div className="carousel-item">
                                                            <div className="video-item" onClick={this.openModal2}>
                                                                <img src="https://img.youtube.com/vi/UqUFsQav9Ys/0.jpg"/>
                                                                <p className="small-text">Prof. Philippe Rosinski: How to excel in a complex and culturally diverse world?</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <a className="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Previous</span>
                                                    </a>
                                                    <a className="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Next</span>
                                                    </a>

                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    	</div>

                                </div>
                                </div>
                                </div>


                            <ExecNav/>

                        </div>
                </section>

            <br/><br/><br/><br/>
                <CopyrightBar/>
            </div>
            </div>
        );
    }
}