import React from 'react';
import {Link} from 'react-router-dom';
import { NavBarWhitecz } from "./NavBarWhitecz";
import { CopyrightBarcz } from "./CopyrightBarcz";

export const Brightcz = () => (
    <div className="still-footer">
        <section className="main">

            <NavBarWhitecz/>

            <div className="container-fluid fullheight">
                <div className="row fullheight">

                    <div className="col-lg-9 brightprincipal" id="bright-bg">

                        <div className="info-wrapper" style={{color: "white"}}>

                            <div className="headline">
                                <h1>Bright Principal<span className="r">®</span></h1>
                                <hr className="hr-headline"/>
                            </div>

                            <p>V Bright Principal si do České republiky ráda zvu a hostím globální inspirativní myslitele, aby se o svoji moudrost podělili s podobně smýšlejícími jedinci, 
                            kteří touží vnést do svého života a srdcí více smyslu. </p>
                            <p>Jsem moc ráda, že jsem svou prací mohla přispět například k představení Marshalla Goldsmithe, Johna Mattoneho, Deepaka Chopry, 
                            Philippe Rosinski, Srikumara S. Raa, Willa Linssena a Ala Moscardelliho v CEE regionu. Organizuji sezení, workshopy a retreaty pro lídry 
                            a/nebo za účelem rozvíjení talentu.</p>
                            <div className="mobile-links">
                            <Link to={"/executiveprogramscz"}> <h3>Programy exekutivního<br/> Rozvoje</h3></Link>
                            <br />
                            <Link to={"/mentoringcz"}> <h3>Leadership Rise<span className="small-r">®</span> Mentoring</h3></Link>
                            </div>

                        </div>


                    </div>
                    <div className="col-lg-3 fixedNav bright-nav">
                        <div className="nav desktop-nav">
                            <div className="nav-content">
                                <Link to={"/brightprincipalcz"}><h2>Bright Principal<span className="small-r">®</span></h2></Link>
                                <hr className="h-line pink" />
                                <br />
                                <Link to={"/executiveprogramscz"}> <h3>Programy Exekutivního<br/> Rozvoje</h3></Link>
                                <br />
                                <Link to={"/mentoringcz"}> <h3>Leadership Rise<span className="small-r">®</span> Mentoring</h3></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <CopyrightBarcz/>
    </div>

);
