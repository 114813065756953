import ReactDOM from "react-dom";
import React, { Component } from 'react';
import ModalVideo from 'react-modal-video'


function VideoList(props) {

    const videos = props;
    console.log(videos);

    const listItems = videos.map((video) =>
        <li>{video}</li>
    );
    return (
        <ul>{listItems}</ul>
    )
}

export default VideoList;