import React, { Component, useRef } from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {ExecNav} from "./ExecNav";
import { CopyrightBar } from "./CopyrightBar";
import { NavBarWhite } from "./NavBarWhite";


export const Executive = () => {

    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

    const upcoming = useRef();
    const executeScroll = () => scrollToRef(upcoming);

    const goldsmith = useRef();
    const executeScroll2 = () => scrollToRef(goldsmith);

    const mattone = useRef();
    const executeScroll3 = () => scrollToRef(mattone);

    const chopra = useRef();
    const executeScroll4 = () => scrollToRef(chopra);

    const rosinski = useRef();
    const executeScroll5 = () => scrollToRef(rosinski);

    const linsen = useRef();
    const executeScroll6 = () => scrollToRef(linsen);

    const rao = useRef();
    const executeScroll7 = () => scrollToRef(rao);

    return (

        <div>
            <section className="main">

                <NavBarWhite/>

                <div className="container-fluid fullheight">
                    <div className="row fullheight">

                        <div className="col-lg-9" id="exec-bg">


                            <div className="mt-5 mb-5 pt-5">

                                <div style={{color: "white"}}>
                                    <h1>Executive Development Programs</h1>
                                    <hr className="hr-headline" />
                                </div>


                            </div>

                            <div ref={goldsmith} className="mb-3">

                                <Link to={"/goldsmith"}>
                                    <div className="row speakerCard">

                                        <div className="col-sm-3">
                                            <img src={require("../img/eva-goldsmith2.jpg")} className="img-fluid"/>
                                        </div>

                                        <div className="col-sm-9">
                                            <div className="vertical-center" style={{left: 0, right: 0}}>
                                                <h3 className="spacing text-center text-uppercase"><b>Marshall Goldsmith</b></h3>
                                            </div>
                                        </div>

                                    </div>
                                </Link>
                            </div>

                            <div ref={mattone} className="mb-3">

                                <Link to={"/mattone"}>
                                <div className="row speakerCard">

                                    <div className="col-sm-9">
                                        <div className="vertical-center" style={{left: 0, right: 0}}>
                                            <h3 className="spacing text-center text-uppercase"><b>John Mattone</b></h3>
                                        </div>
                                    </div>

                                    <div className="col-sm-3">
                                        <img src={require ("../img/mattone-pic.png")} className="img-fluid"/>
                                    </div>

                                </div>
                                </Link>
                            </div>


                            <div ref={chopra} className="mb-3">

                                <Link to={"/chopra"}>
                                <div className="row speakerCard">
                                    <div className="col-sm-3">
                                        <img src={require ("../img/eva-deepakchopra.JPG")} className="img-fluid"/>
                                    </div>


                                    <div className="col-sm-9">
                                        <div className="vertical-center" style={{left: 0, right: 0}}>
                                            <h3 className="spacing text-center text-uppercase"><b>Deepak Chopra</b></h3>
                                            {/*<div className="card mb-3">


                                                <div className="row no-gutters">
                                                    <div className="col-md-4">
                                    <img src={require ("../program-files/chopra/future-of-wellbeing.png")} className="card-img" alt="..."/>
                                </div>
                                                    <div className="col-12">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Dr. Deepak Chopra: The Future of Wellbeing</h5>
                                                            <p className="card-text">
                                                                <br/>
                                                                <a href={"https://www.youtube.com/watch?v=b7lFD0xlJE8"} target="_blank">Link to a video</a>

                                                            </p>
                                                            <Link to={"/details"}><button className="btn btn-primary btn-small">READ MORE</button></Link>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>


                            <div ref={rosinski} className="mb-3">

                                <Link to={"/rosinski"}>
                                <div className="row speakerCard">

                                    <div className="col-sm-9">
                                        <div className="vertical-center" style={{left: 0, right: 0}}>
                                            <h3 className="spacing text-center text-uppercase"><b>Philippe Rosinski</b></h3>
                                            {/*<div className="card mb-3">
                                                <div className="row no-gutters">
                                                    <div className="col-12">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Success Across Cultures – Leveraging Cultural Differences for Sustainable Grow: Prof. Philippe Rosinski, Prague, November 15 2013</h5>
                                                            <p className="card-text">
                                                                <i>Philippe Rosinski</i></p>
                                                            <Link to={"/details"}><button className="btn btn-primary btn-small">READ MORE</button></Link>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mb-3">
                                                <div className="row no-gutters">
                                                    <div className="col-12">
                                                        <div className="card-body">
                                                            <h5 className="card-title">How to excel in a complex and culturally diverse world?</h5>
                                                            <p className="card-text">
                                                                <a href={"https://www.youtube.com/watch?v=UqUFsQav9Ys"} target="_blank">Link to a video</a>
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="mb-md-5 mt-md-5">

                                    <div className="card mb-3">
                                        <div className="row no-gutters">
                                            <div className="col-12">
                                                <div className="card-body">
                                                    <h5 className="card-title">Global Leadership Development Program, Forest Reteat with Philippe Rosinski, October 2018</h5>
                                                    <p className="card-text">
                                                        <a href={"https://youtu.be/e0eBZGKt560"} target="_blank">Link to a video</a>
                                                    </p>
                                                    <Link to={"/details"}><button className="btn btn-primary btn-small">READ MORE</button></Link>


                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="card mb-3">
                                        <div className="row no-gutters">
                                            <div className="col-12">
                                                <div className="card-body">
                                                    <h5 className="card-title">Global Leadership Development Program, Forest Reteat with Philippe Rosinski, October 2017</h5>
                                                    <p className="card-text">
                                                        <br/>
                                                        <Link to={"/details"}><button className="btn btn-primary btn-small">READ MORE</button></Link>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card mb-3">
                                        <div className="row no-gutters">
                                            <div className="col-12">
                                                <div className="card-body">
                                                    <h5 className="card-title">Leading and Coaching across Cultures: Prof. Philippe Rosinski, Shanghai, March 5-7 2014</h5>
                                                    <p className="card-text"></p>
                                                    <Link to={"/details"}><button className="btn btn-primary btn-small">READ MORE</button></Link>

                                                </div>
                                            </div>*/}
                                        </div>
                                    </div>

                                    <div className="col-sm-3">
                                        <img src={require ("../img/rosinski233.png")} className="img-fluid"/>
                                    </div>

                                </div>
                                </Link>
                            </div>


                            <div ref={linsen} className="mb-3">

                                <Link to={"/linsen"}>
                                <div className="row speakerCard">
                                    <div className="col-sm-3">
                                        <img src={require ("../img/linssen3.png")} className="img-fluid"/>
                                    </div>

                                    <div className="col-sm-9">
                                        <div className="vertical-center" style={{left: 0, right: 0}}>
                                            <h3 className="spacing text-center text-uppercase"><b>Will Linssen</b></h3>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>



                            <div ref={rao} className="mb-3">

                                <Link to={"/rao"}>
                                <div className="row mb-5 speakerCard">

                                    <div className="col-sm-9">
                                        <div className="vertical-center" style={{left: 0, right: 0}}>
                                            <h3 className="spacing text-center text-uppercase"><b>Srikumar Rao</b></h3>
                                            {/*<div className="card mb-3">


                                                <div className="row no-gutters">
                                                    <div className="col-12">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Experience a more fulfilling life - Prof. Dr. S. Rao, Prague, 16 – 17 February 2012</h5>
                                                            <p className="card-text">
                                                                <br/>Dr. Rao teaches a hugely popular course at Columbia Business School, <br/>
                                                                London Business School and others world leading business schools. <br/>
                                                                He is an author of an international bestseller “Are You Ready to Succeed: Unconventional Strategies for Achieving Personal Mastery in Business and Life”. </p>
                                                            <Link to={"/details"}><button className="btn btn-primary btn-small">READ MORE</button></Link>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className="col-sm-3">
                                        <img src={require ("../img/srikumar-rao.JPG")} className="img-fluid"/>
                                    </div>

                                </div>
                                </Link>
                            </div>



                            </div>


                        <ExecNav/>

                    </div>
                </div>
            </section>
            <CopyrightBar/>
        </div>

    );
}

