import React from 'react';
import {Link} from 'react-router-dom';
import PdfInterview from '../eva-interview.pdf';
import { NavBar } from "./NavBar";
import { CopyrightBar } from "./CopyrightBar";

export const Interview = () => (
    <div>
    <div id="backgroundImg">
        <section className="main interview" id="eva">

            <NavBar/>

            <div className="container-fluid fullheight">
                <div className="row fullheight">


                    <div className="col-lg-9">

                        <div className="row">
                        <div className="col-md-5">
                        <img className="mt-5 vertical-center" style={{width: 80 + "%"}} src={require('../img/interview-img.png')} />
                        </div>

                        <div className="col-md-7">

                        <div style={{marginTop: 15 + "%"}}>
                        <div className="headline">
                            <h1>Eva's Interview in <i>Business Woman</i> -magazine</h1>
                            <hr className="hr-headline" />
                        </div>

                            <p>Vystudovala dvě vysoké školy, jedenáct let žila ve Velké Británii a je držitelkou přibližně desítky
                                mezinárod- ních certifikátů pro koučování vedoucích pracovníků, leadershipu, týmů, rodiny a vztahů i
                                koučování napříč kulturami.</p>

                        <p>“Budovala tři úspěšné firmy. Letos, místo aby těžila z výsledků své dosavadní práce, založila pobočku
                            své úspěšné české firmy Principal Co- aching ve Velké Británii – a začíná tam znovu od začátku.
                            „Práce mě moc baví a vlastně ji ani za práci nepovažuji. To asi bude ten trik,“ prozrazuje usměva-
                            vá a pohodová exekutivní koučka Eva Benešová. </p>

                            <div className="mt-5 mb-5">
                        <a href={PdfInterview} target="_blank"><button className="btn contactBtn">Click here to read full interview</button></a>
                        </div>
                        </div>
                        </div>
                        </div>


                    </div>
                    <div className="col-lg-3 fixedNav">
                        <div className="nav desktop-nav">
                            <div className="nav-content">
                                <Link to={"/eva"}><h2>Eva Benesova</h2></Link>
                                <hr className="h-line pink" />
                                <br />
                                <Link to={"/eva"}> <h3>About Eva</h3></Link>
                                <br />
                                <Link to={"/interview"}> <h3 className="pink-text">Interview</h3></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    </div>
        <CopyrightBar/>
    </div>
);
