import React, { Component, useRef } from 'react';
import ReactDOM from 'react-dom'
import {Link} from 'react-router-dom';
import ProgramData from '../programs.json';
import PropTypes from 'prop-types';
import {ExecNav} from "./ExecNav";
import { CopyrightBar } from "./CopyrightBar";
import { NavBarWhite } from "./NavBarWhite";
import {videoData} from '../videos.js';
import ReactFancyBox from 'react-fancybox'
import '../../node_modules/react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video'
import VideoList from './VideoList';
import myVideo from '../img/goldsmith-video.mp4';

export class Goldsmith extends React.Component {

    constructor () {
        super()
        this.state = {
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false
        }
        this.openModal1 = this.openModal1.bind(this);
        this.openModal2 = this.openModal2.bind(this);
        this.openModal3 = this.openModal3.bind(this);
        this.openModal4 = this.openModal4.bind(this);
        this.openModal5 = this.openModal5.bind(this);
    }

    openModal1 () {
        this.setState({isOpen1: true})
    }
    openModal2 () {
        this.setState({isOpen2: true})
    }
    openModal3 () {
        this.setState({isOpen3: true})
    }
    openModal4 () {
        this.setState({isOpen4: true})
    }
    openModal5 () {
        this.setState({isOpen5: true})
    }


    render () {

        return (

            <div>

            <div id="backgroundImg">
                <section className="main">

                    <NavBarWhite/>

                    <div className="container-fluid fullheight">
                        <div className="row fullheight">

                            <div className="col-lg-9" id="speaker-bg">

                                <div className="speaker-list mt-5 pt-5">

                                    <div className="row">
                                        <div className="col-md-5">
                                            <img src={require("../img/eva-goldsmith2.jpg")}
                                                 className="img-fluid p-3 vertical-center"/>
                                        </div>


                                        <div className="col-md-7">

                                            <div className="">

                                                <div className="headline pb-5 m-0">
                                                    {/* {ProgramData.map((programDetail, index) => {
                                        console.log(programDetail);
                                        return <h1>{programDetail.speaker}</h1>
                                    })} */}

                                                    <h1>{ProgramData[0].speaker}</h1>

                                                    <hr className="hr-headline"/>
                                                </div>

                                                <p>Dr. Marshall Goldsmith is the world authority in helping successful
                                                    leaders achieve positive,
                                                    lasting change in behaviour: for themselves, their teams.</p>

                                                <ul>
                                                    <li>He is the only Thinkers 50 two-time #1 Leadership Thinker in the
                                                        World.
                                                    </li>
                                                    <li>He has been recognised as the #1 Executive Coach in the World
                                                        every year for the past decade.
                                                    </li>
                                                    <li>Marshall is the # 1 New York Times bestselling author or editor
                                                        of 40 books, which have been translated
                                                        into 32 languages.
                                                    </li>
                                                    <li>Two of these books, <i>Triggers</i> and <i>What Got You Here Won’t Get You
                                                        There</i> have been recognised by
                                                        Amazon in their listing of the Top 100 Leadership and Success
                                                        Books ever written.
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                    
<ExecNav/></div><br/><br/>
                                <div className="a d">
                                <div className="row">
                                        <div className="col-lg-6 imgmarshall"> <br/><br/><br/><br/><br/><br/>
                                        <img src={require("../img/text.png")} 
                                        className="imgmarshall"/>
                                        <br/><br/><br/><br/><br/>
                                        <a href="http://www.marshallgoldsmith.cz" target="_blank"><img src={require("../img/button.png")} className="imgmarshallbtn"/></a>
                                        </div>

                                    <div className="col-lg-6 b">
                                        <br/><br/><br/><br/>
                                        <video controls loop style={{height: 70 + "%"}}>
                                            <source src={myVideo} type="video/mp4"/>
                                        </video>
                                    </div>

                                </div>
                                </div>
                   
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <div className="container-fluid fullheight c">
                                    <div className="row fullheight">

                                    <div className="col-lg-9" id="speaker-bg">

                                        <div className="speaker-list mt-5 pt-5">
                                        <div className="row">
                                            <div className="col-12 video-container mt-5">
                                                <div className="video-list">

                                                <h1>Video Player</h1>

                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen1} videoId='nC_1vbU9Pic' onClose={() => this.setState({isOpen1: false})} />
                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen2} videoId='dnYBbJN4hCc' onClose={() => this.setState({isOpen2: false})} />
                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen3} videoId='omWRHsrmuTs' onClose={() => this.setState({isOpen3: false})} />
                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen4} videoId='8MYkXbNNUyk' onClose={() => this.setState({isOpen4: false})} />
                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen5} videoId='KcK7vqvNZI8' onClose={() => this.setState({isOpen5: false})} />

                                                <div id="carouselExampleControls" className="carousel slide desktop-carousel" data-ride="carousel">
                                                    <div className="carousel-inner">
                                                        <div className="carousel-item active">
                                                            <div className="video-item" onClick={this.openModal1}>
                                                                <img src="https://img.youtube.com/vi/nC_1vbU9Pic/0.jpg"/>
                                                                <p className="small-text">The Earned Life TRAILER</p>
                                                            </div>

                                                            <div className="video-item" onClick={this.openModal2}>
                                                                <img src="https://img.youtube.com/vi/dnYBbJN4hCc/0.jpg"/>
                                                                <p className="small-text">Marshall Goldsmith</p>
                                                            </div>

                                                            <div className="video-item" onClick={this.openModal3}>
                                                                <img src="https://img.youtube.com/vi/omWRHsrmuTs/0.jpg"/>
                                                                <p className="small-text">Marshall Goldsmith speaks at the Synergy Global Forum at Moscow's Olimpiysky Arena</p>
                                                            </div>
                                                        </div>
                                                        <div className="carousel-item">
                                                            <div className="video-item" onClick={this.openModal4}>
                                                                <img src="https://img.youtube.com/vi/8MYkXbNNUyk/0.jpg"/>
                                                                <p className="small-text">Coaching Entrepreneurial Leaders: Working with Startups and Entrepreneurial CEOs</p>
                                                            </div>

                                                            <div className="video-item" onClick={this.openModal5}>
                                                                <img src="https://img.youtube.com/vi/KcK7vqvNZI8/0.jpg"/>
                                                                <p className="small-text">Lessons from a Great Venture Capitalist: Dan Levitan and Marshall Goldsmith</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Previous</span>
                                                    </a>
                                                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Next</span>
                                                    </a>

                                                </div>

                                                <div id="carouselExampleControls2" className="carousel slide mobile-carousel" data-ride="carousel">
                                                    <div className="carousel-inner">
                                                        <div className="carousel-item active">
                                                            <div className="video-item" onClick={this.openModal1}>
                                                                <img src="https://img.youtube.com/vi/nC_1vbU9Pic/0.jpg"/>
                                                                <p className="small-text">The Earned Life TRAILER</p>
                                                            </div>
                                                        </div>
                                                        <div className="carousel-item">
                                                            <div className="video-item" onClick={this.openModal2}>
                                                                <img src="https://img.youtube.com/vi/dnYBbJN4hCc/0.jpg"/>
                                                                <p className="small-text">Marshall Goldsmith</p>
                                                            </div>
                                                        </div>
                                                        <div className="carousel-item">
                                                            <div className="video-item" onClick={this.openModal3}>
                                                                <img src="https://img.youtube.com/vi/omWRHsrmuTs/0.jpg"/>
                                                                <p className="small-text">Marshall Goldsmith speaks at the Synergy Global Forum at Moscow's Olimpiysky Arena</p>
                                                            </div>
                                                        </div>
                                                        <div className="carousel-item">
                                                            <div className="video-item" onClick={this.openModal4}>
                                                                <img src="https://img.youtube.com/vi/8MYkXbNNUyk/0.jpg"/>
                                                                <p className="small-text">Coaching Entrepreneurial Leaders: Working with Startups and Entrepreneurial CEOs</p>
                                                            </div>
                                                        </div>
                                                        <div className="carousel-item">
                                                            <div className="video-item" onClick={this.openModal5}>
                                                                <img src="https://img.youtube.com/vi/KcK7vqvNZI8/0.jpg"/>
                                                                <p className="small-text">Lessons from a Great Venture Capitalist: Dan Levitan and Marshall Goldsmith</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <a className="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Previous</span>
                                                    </a>
                                                    <a className="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Next</span>
                                                    </a>

                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>

                                    </div>
                                    </div>
                                    </div>




                            <ExecNav/>
        

                </div>            
                </section>
                
            <br/><br/><br/><br/>
            <CopyrightBar/>
            </div>
            </div>
        

        );
    }
}

