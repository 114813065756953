import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import ProgramData from '../programs.json';
import PropTypes from 'prop-types';

export const Details = () => (

    <div id="backgroundImg">
        <section className="main">
            <div className="container-fluid fullheight">
                <div className="row fullheight">
                    <div className="col-lg-9">

                        <h3 className="spacing">This page will display the details of a specific program</h3>

                        {ProgramData.map((programDetail, index) => {
                            return <h1>{programDetail.title}</h1>
                        })}

                    </div>


                    <div className="col-lg-3 fixedNav">
                        <div className="nav desktop-nav">
                            <div className="nav-content">
                                <Link to={"/executiveprograms"}><h2>Executive Development Programs</h2></Link>
                                <hr className="h-line pink" />
                                <br />
                                <Link to={"/executiveprograms"}> <h3 className="pink-text">Upcoming Programs</h3></Link>
                                <Link to={"/executiveprograms"}> <h6>Marshall Goldsmith</h6></Link>
                                <Link to={"/executiveprograms"}> <h6>John Mattone</h6></Link>
                                <Link to={"/executiveprograms"}> <h6>Deepak Chopra</h6></Link>
                                <Link to={"/executiveprograms"}> <h6>Philippe Rosinski</h6></Link>
                                <Link to={"/executiveprograms"}> <h6>Will Linsen</h6></Link>
                                <Link to={"/executiveprograms"}> <h6>Srikumar S. Rao</h6></Link>
                            </div>
                            <button className="btn btn-primary contact-btn">CONTACT</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
);

