import React from 'react'
import {Link} from 'react-router-dom';
import { NavBarWhitecz } from "./NavBarWhitecz";
import { CopyrightBarcz } from "./CopyrightBarcz";


export const Kontakt = () => (

    <div id="contact-bg">
        <section className="main" id="contact">
            <NavBarWhitecz />
            <div className="container-fluid fullheight">


                <div className="row fullheight">
                    <div className="col-md-8">

                        <div className="vertical-center" style={{width: 100 + "%"}}>
                            <h1 className="principal">PhDr. Ing. Eva Benešová</h1>

                            <div className="row">

                            <div className="col-md-5">
                                <form className="contactform vertical-center" style={{width: 90 + "%"}} method="post">
                                    <div className="form-group">
                                        <input type="text" name="name" className="form-control" placeholder="Jméno"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" name="mail" className="form-control" placeholder="Emailová adresa"/>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" name="message" id="exampleFormControlTextarea1" rows="3" placeholder="Vaše zpráva"/>
                                    </div>
                                    <button type="submit" name="submit" className="btn btn-primary submitBtn" style={{float: "right", borderWidth: 1 + "px"}}>Poslat</button>
                                </form>
                            </div>

                            <div className="col-md-7" style={{color: "white"}}>

                                <div id="prague">
                                    <h3><b>Česká republika</b></h3>
                                    <p className="address">Liliová 248/8<br/>
                                        Praha – Staré Město, 110 00<br/>
                                        Česká republika</p>
                                </div>

                                
                                <div>
                                    <h3><b>Telefon:</b></h3>
                                    <p className="address">+420 602 571 944<br/>
                                        </p>
                                </div>
                                <div>
                                    <h3><b>Email:</b></h3>
                                    <p className="address">eva@principalcoaching.com</p>
                                    <p class="mobile-only"> <br/><br/>a<br/></p>
                                </div>
                            </div>

                            </div>
                        </div>

                        {/*

                        <div className="locations" id="prague">
                        <h5 className="pink-text"><b>Praha</b></h5>
                        <p className="address">Liliová 248/8<br/>
                        Praha – Staré Město<br/>
                        110 00</p>
                        </div>
                        */}
                    </div>
                </div>
            </div>
            <CopyrightBarcz/>
        </section>
    </div>
);
