import React from 'react';
import {Link} from 'react-router-dom';
import { CopyrightBar } from "./CopyrightBar";
import { NavBarWhite } from "./NavBarWhite";


export const Mentoring = () => (
    <div id="backgroundImg">
        <section className="main">

            <NavBarWhite/>

            <div className="container-fluid fullheight">
                <div className="row fullheight">

                    <div className="col-lg-9 mentoring" id="exec-bg">

                        <div className="w-50" style={{marginTop: 37 + "%"}}>

                        <div className="headline">
                            <h1 style={{color: "white"}}>Leadership Rise<span className="r">®</span> Mentoring</h1>
                            <hr className="hr-headline" />
                        </div>

                        <h3>Senior CEO to CEO</h3>

                            <Link to={"/contact"}><h3>Please contact Eva for more information</h3></Link>
                            <div className="mobile-links mt-5">
                                <Link to={"/brightprincipal"}> <h3>Back to Bright Principal</h3></Link>
                            </div>

                        </div>

                    </div>
                    <div className="col-lg-3 fixedNav bright-nav">
                        <div className="nav desktop-nav">
                            <div className="nav-content">
                                <Link to={"/brightprincipal"}><h2>Bright Principal<span className="small-r">®</span></h2></Link>
                                <hr className="h-line pink" />
                                <br />
                                <Link to={"/executiveprograms"}> <h3>Executive Development<br/> Programs</h3></Link>
                                <br />
                                <Link to={"/mentoring"}> <h3 className="pink-text">Leadership Rise<span className="small-r">®</span> Mentoring</h3></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CopyrightBar/>
        </section>

    </div>
);
