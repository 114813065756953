import React, { Component, useRef } from 'react';
import {Link} from 'react-router-dom';
import ProgramData from '../programs.json';
import PropTypes from 'prop-types';
import {ExecNavcz} from "./ExecNavcz";
import { CopyrightBarcz } from "./CopyrightBarcz";
import { NavBarWhitecz } from "./NavBarWhitecz";
import ModalVideo from 'react-modal-video';


export class Raocz extends React.Component {

    constructor () {
        super()
        this.state = {
            isOpen1: false,
            isOpen2: false
        }
        this.openModal1 = this.openModal1.bind(this);
        this.openModal2 = this.openModal2.bind(this);
    }

    openModal1 () {
        this.setState({isOpen1: true})
    }
    openModal2 () {
        this.setState({isOpen2: true})
    }

    render () {

        return (

            <div>
            <div id="backgroundImg">
                <section className="main">

                    <NavBarWhitecz/>
<br/>
<p class="mobile-only"><br/><br/><br/></p>
                    <div className="container-fluid fullheight">
                        <div className="row fullheight">

                            <div className="col-lg-9" id="speaker-bg">


                                <div className="speaker-list mt-5">

                                    <div className="row">
                                        <div className="col-md-5 e">
                                            <img src={require("../img/srikumar-rao.JPG")} className="img-fluid p-3 vertical-center"/>
                                        </div>


                                        <div className="col-md-7">

                                                <div className="headline pb-5 m-0">
                                                    {/* {ProgramData.map((programDetail, index) => {
                                        console.log(programDetail);
                                        return <h1>{programDetail.speaker}</h1>
                                    })} */}

                                                    <h1>{ProgramData[5].speaker}</h1>

                                                    <hr className="hr-headline"/>
                                                </div>

                                                <p>Srikumar je zakladatel The Rao Institute a tvůrce průkopnického kurzu Creativity & Personal Mastery.
                                                 Srikumar Rao pomáhá lídrům po celém světe změnit jejich životy, aby mohli zažívat hojné radosti nehledě na 
                                                 to, co jim přijde do cesty. Dr. Rao je řečníkem každoroční konference a platformy TED, autor a tvůrce průkopnického 
                                                 kurzu Creativity & Personal Mastery. </p>
                                                <br/>
                                                <p>Prof. Srikumar S. Rao měl možnost pracovat s lídry CEE regionu při těchto příležitostech:</p>

                                            <ul className="program-list">
                                                <li><p
                                                    className="mb-4">Creativity and Personal Mastery with Senior Business, Education,
                                                    Social Leaders, Prague, November 19-21, 2011</p>
                                                </li>
                                                <li><p
                                                    className="mb-4">Creativity and Personal Mastery Workshop, Prague, May 10,
                                                    2012</p>
                                                </li>
                                                <li><p
                                                    className="mb-4">Leadership Accelerator, Prague, February 11-12, 2013</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 video-container mt-5">
                                            <div className="video-list">

                                                <h1>Video Přehrávač</h1>

                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen1} videoId='uLDz7emCnZI' onClose={() => this.setState({isOpen1: false})} />
                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen2} videoId='vBlWbV64N4I' onClose={() => this.setState({isOpen2: false})} />

                                                <div id="carouselExampleControls" className="carousel slide desktop-carousel" data-ride="carousel">
                                                    <div className="carousel-inner">
                                                        <div className="carousel-item active">
                                                            <div className="video-item" onClick={this.openModal1}>
                                                                <img src="https://img.youtube.com/vi/uLDz7emCnZI/0.jpg"/>
                                                                <p className="small-text">How To Eliminate Stress And Anxiety FOREVER | Srikumar Rao</p>
                                                            </div>

                                                            <div className="video-item" onClick={this.openModal2}>
                                                                <img src="https://img.youtube.com/vi/vBlWbV64N4I/0.jpg"/>
                                                                <p className="small-text">How To Rewire Your Mind For Greater Happiness & Success | Dr. Srikumar Rao</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Previous</span>
                                                    </a>
                                                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Next</span>
                                                    </a>

                                                </div>

                                                <div id="carouselExampleControls2" className="carousel slide mobile-carousel" data-ride="carousel">
                                                    <div className="carousel-inner">
                                                        <div className="carousel-item active">
                                                            <div className="video-item" onClick={this.openModal1}>
                                                                <img src="https://img.youtube.com/vi/uLDz7emCnZI/0.jpg"/>
                                                                <p className="small-text">How To Eliminate Stress And Anxiety FOREVER | Srikumar Rao</p>
                                                            </div>
                                                        </div>
                                                        <div className="carousel-item">
                                                            <div className="video-item" onClick={this.openModal2}>
                                                                <img src="https://img.youtube.com/vi/vBlWbV64N4I/0.jpg"/>
                                                                <p className="small-text">How To Rewire Your Mind For Greater Happiness & Success | Dr. Srikumar Rao</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <a className="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Previous</span>
                                                    </a>
                                                    <a className="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Next</span>
                                                    </a>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <ExecNavcz/>
                        </div>
                    </div>

                </section>

            </div><br/><br/><br/>
                <CopyrightBarcz/>
            </div>
        );
    }
}

