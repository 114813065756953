import React from 'react'
import {Link} from 'react-router-dom';
import { CopyrightBarcz } from "./CopyrightBarcz";
import { NavBarWhitecz } from "./NavBarWhitecz";



export const Domu = () => (
    <div>
    <div className="still-footer">
    <div id="backgroundImg-home">
        <section className="main">

            <NavBarWhitecz/>

            <div className="container-fluid fullheight">
                <div className="row fullheight">
                    <div className="col-12">

                        <div className="home-text1" style={{color: "white"}}>
                            <h1 className="principal">Vítejte na stránkách Principal Coaching</h1>
                            <hr className="hr-landing f" style={{borderColor: "white"}}/>
                            <h1 className="coaching">Evy Benešové</h1>
                        </div>


                        {/*<img src={require('../img/logo.png')} className="main-img mb-5 pb-4 float-right" style={{width: 200 + "px"}}/>*/}

                    </div>
                </div>
            </div>

        </section>

    </div>


    </div>
    
    <CopyrightBarcz/>
    </div>
);
