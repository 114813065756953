import React from 'react';
import {Link} from 'react-router-dom';
import { CopyrightBar } from "./CopyrightBar";
import { NavBar } from "./NavBar";

export const Eva = () => (
    <div>
    <div id="backgroundImg" className="">
        <section className="main" id="eva">

            <NavBar/>

            <div className="container-fluid fullheight">
                <div className="row fullheight">

                    {/* <Link to={"/"}><p className="close" /></Link> */}

                    <div className="col-lg-9">
                        <div className="letter">

                                <div className="headline txtcenter">
                                    <h1>Dr. Eva Benesova</h1>
                                    <hr className="w" />
                                        <p className="small-text"><i>- Marshall Goldsmith Stakeholder Centered Coaching
                                            <br/>- Philippe Rosinski Coaching Across Cultures and Global Coaching
                                            <br/>- Executive Coaching and Development</i></p>
                                </div>

<img className="top-img" src={require('../img/1.png')} />
                            <p className="small-text1">“I feel like butter, thinly spread across a large slice of bread,”
                                said one of my clients during our first meeting.
                                I must underscore that this client appeared successful in all observable aspects: career, personal life,
                                family, parenting, and relationships—and at first glance, appeared content. He had all he could ask for,
                                his life goals fulfilled in just 42 years, and it seemed he could not complain about anything.
                                Except for an inner feeling that brought him to me.
                            </p>

                            <p><b>Have you ever dealt with a similar situation, where you felt similarly?
                                Have you ever had the feeling that despite having an overall successful life,
                                something inside you is accumulating—and that you must do something important and fundamental to release it?
                                <br/>
                                <i>Be at ease. You are not alone.</i>
                            </b></p>

                            <div className="aa">
                            <ul>
                                <li>Do you want to strike new possibilities, identify your priorities, and move towards new perspectives?</li>
                                <li>Do you want to stop moving in circles and find what truly suits you?</li>
                                <li>Do you want more free time for yourself?</li>
                                <li>Do you want to overcome resistance to change quickly and reduce your losses to regain happiness in your life?</li>
                                <li>Do you want to know precisely what change you need to dramatically move forward?</li>
                                <li>Do you want to gain inner strength and energy through important change, and elevate your life to the next level?</li>
                                <li><b>Do you want to stop stagnating and become totally confident in yourself?</b></li>
                            </ul><br/>
                            </div>

                            <p>I have passionately worked with people for over 20 years to support them during various changes in their lives,
                                and to help them break out of unwanted and undesirable habits. I am yet more passionate about developing the
                                strong sides of their personalities and social and managerial toolsets. Primarily, I use methods of Executive
                                Psychological Coaching and act as the client’s partner for deep conversations and self-reflection.
                            </p>

                            <p>Working with individual clients is something I have known I wanted to do since I was 10 years old, and since then,
                                I have directed all my energies towards that goal. I enjoy my work immensely and it makes me happy to be able
                                to contribute to positive change for my clients. My typical client is someone in a senior executive position or
                                the owner of a firm and their teams and talents that await further elevation.
                            </p>

                            <h3 className="mt-5"><b>What can you expect from me and Principal Coaching?</b></h3>

                            <ol>
                                <li>I have extensive international experience. Most of my clients come from referrals.</li>
                                <li>I coach successful individuals at C-level or talents and craft individual developmental programs for them.</li>
                                I engage in personal meetings with them, and if needed, group meetings as well.
                                <li>I create team workshops, retreats, and experiential programs tailored to fit the group, based on
                                    the individual personality profiles of participants and the needs of the team.</li>
                                <li>As a certified psychologist I conduct psychological and career evaluations of each personality—to this end I
                                    use the finest and most detailed online questionnaires available for healthy individuals worldwide.</li>
                                <li>As a part of Bright Principal©, I organize workshops with inspiring international speakers that I invite
                                for my corporate clients in the Czech Republic. I have, with great success, organized single and multi-day workshops
                                in the Czech region for John Mattone, Phillipe Rosinski, Will Linssen, Srikumar S. Rao and Al Moscardelli.
                                    I have cooperated on three occasions in Prague-based projects presenting Deepak Chopra in 	the CEE region.</li>
                                <li>As a part of the Leadership Rise© program, I connect senior C-level mentors with their C-level clients.</li>

                            </ol>

                                    <h3 className="mt-5"><b>My background and experience</b></h3>

                                    <p>I have lived in Great Britain for 12 years. In 2006 I started building my
                                        first executive psychological coaching practice under the name of Principal Coaching.
                                        My work reaches individuals in Prague and London and my clients can be found in
                                        various countries within multiple continents. </p>

                            <p>In 2007 I met <b>Phillipe Rosinski</b>, the most esteemed figure in the realm of Global
                                Coaching and Coaching Across Cultures. Philippe’s innovative approach of adding a
                                crucial and often neglected intercultural dimension to leadership and coaching has brought
                                him worldwide acclaim. I get along with Phillipe very well, and am responsible for the presentation
                                of his concepts of coaching in the Czech Republic and Slovakia. I intensely work with him as his
                                Associate for the CEE region. I became the first executive coach in Eastern Europe to receive the
                                Certification of Leading and Coaching Across Culture—COF Assessment and use Philippe’s holistic,
                                global concept of coaching. <b>I work with my clients across physiological, psychological, managerial,
                                    cultural, influential, and spiritual perspectives. </b></p>

                            <div className="float-right quote-wrapper">
                                <div className="quote quote-right m-0 p-0" >
                                    <h4>"It's never too <br/> late to be <br/> what you might <br/>have been."</h4>
                                    <h5 className="pink-text">George Elliot</h5>
                                </div>

                                <img src={require('../img/eva.jpg')} className="content-img eva-img ml-4"/>
                            </div>

                            <p>In 2014 I met <b>Marshall Goldsmith</b>, a #1 global personality in the category of Leadership Thinker,
                                #1 Executive Coach Thinkers50, and was the first in the Czech Republic and third in Eastern Europe
                                to receive three of his certifications. I became his Associate and began to propagate his concept of
                                <b> measurable Executive Stakeholders Centered Coaching</b> in the Czech Republic and Slovakia. Marshall is one
                                of my greatest human and professional inspirations and meeting him changed my life.
                                Thanks to him I also met <b>John Mattone</b>, the personal coach of Steve Jobs, and began cooperating
                                with him. With great success, I brought his <b>Intelligent Leadership program</b> to Prague and the CEE region
                                for the first time, in 2019. </p>


                            <p><i>A thorough study examining over 11,000 business leaders from international organizations came
                                to the conclusion that 95% of people that participated in the Stakeholder Centered Coaching
                                measurably increased the effectiveness of their leadership (Leadership is a contact sport,
                                Strategy and Business, September 2004). </i></p>

                            <p>I am a Doctor of Psychology and a Master of Economics. I also studied the theme of Group
                                Analysis at the Institute of Group Analysis in London. Apart from <b>Marshall Goldsmith</b> and <b>Philippe Rosinski</b>,
                                I have also received a number of internationally recognized top coaching certificates from global names
                                such as <b>John Whitmore</b>, <b>Timothy Gallwey</b>, <b>Marilyn Atkinson</b>, and <b>Will Linssen</b>. I acted as Assistant Professor
                                at the Faculty of Psychology and Sociology of Management at the University of Economics in Prague and
                                co-founded two firms for the development of social and managerial skills. My doctoral research involved
                                MBA graduates in various positions in a number of investment banks in the City of London. </p>

                            <p>I am influenced by <b>Deepak Chopra</b>, modern neuroscience, and Eastern philosophy and psychology.
                                I am intensely aware of the significance of research into quantum physics and the possibilities
                                of the depth of spiritual evolution. </p>

                            <p>Among my most esteemed clients are: EY, LEGO EMEA Distribution, Deutsche Bank WM, Novo Nordisk Region Europe,
                                Česká Spořitelna, Arriva SCEE, General Motors Europe, Savensia Fromage, Skoda Auto India, Zatisi Group,
                                Hogan Lowels International, Sephora, ČSOB, Havas Worldwide New Europe, iPrice Group Malaysia, Partners
                                Financial Services, PAN Solutions, and others. </p>

                            <p>If you were captivated by what you just read, wish to work on yourself and change the uncomfortable
                                stereotypes of your life, wish to be content and maximally effective, or seek for yourself or your
                                team an executive coach, take the first step and write to me. I am the person who can help you to nourish
                                positive change within you and guide you in drawing a clear, effective, and functional path.
                            </p>

                            <p>Make this step for yourself before your feelings develop into something that begins to affect the entire
                                balance of your carefully constructed world. Write to me before you decide to take the “happy pill”.
                            </p>

                        </div>

                                <div className="row">
                                    <div className="col-12">

                                        <div className="letter">
                                <h5 className="mt-3 mb-3">I look forward to our cooperation.</h5>
                                        </div>
                                        </div>
                    </div>
<br/>
                    </div>
                    <div className="col-lg-3 fixedNav">
                        <div className="nav desktop-nav">
                            <div className="nav-content">
                                <Link to={"/eva"}><h2>Eva Benesova</h2></Link>
                                <hr className="h-line pink" />
                                <br />
                                <Link to={"/eva"}> <h3 className="pink-text">About Eva</h3></Link>
                                 <br />
                                 <Link to={"/interview"}> <h3>Interview</h3></Link>
                            </div>
                        </div>
                    </div>
                    </div>


            </div>
        </section>
    </div>
        <CopyrightBar/>
    </div>
);
