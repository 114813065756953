import React from 'react';
import {Link} from 'react-router-dom';
import { CopyrightBarcz } from "./CopyrightBarcz";
import { NavBarWhitecz } from "./NavBarWhitecz";


export const Mentoringcz = () => (
    <div id="backgroundImg">
        <section className="main">

            <NavBarWhitecz/>

            <div className="container-fluid fullheight">
                <div className="row fullheight">

                    <div className="col-lg-9 mentoring" id="exec-bg">

                        <div className="w-50" style={{marginTop: 37 + "%"}}>

                        <div className="headline">
                            <h1 style={{color: "white"}}>Leadership Rise<span className="r">®</span> Mentoring</h1>
                            <hr className="hr-headline" />
                        </div>

                        <h3>Od Senior CEO k CEO</h3>

                            <Link to={"/kontakt"}><h3>Kontaktujte prosím Evu pro více informací</h3></Link>
                            <div className="mobile-links mt-5">
                                <Link to={"/brightprincipalcz"}> <h3>Zpět k Bright Principal</h3></Link>
                            </div>

                        </div>

                    </div>
                    <div className="col-lg-3 fixedNav bright-nav">
                        <div className="nav desktop-nav">
                            <div className="nav-content">
                                <Link to={"/brightprincipalcz"}><h2>Bright Principal<span className="small-r">®</span></h2></Link>
                                <hr className="h-line pink" />
                                <br />
                                <Link to={"/executiveprogramscz"}> <h3>Programy Exekutivního<br/> Rozvoje</h3></Link>
                                <br />
                                <Link to={"/mentoringcz"}> <h3 className="pink-text">Leadership Rise<span className="small-r">®</span> Mentoring</h3></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CopyrightBarcz/>
        </section>

    </div>
);
