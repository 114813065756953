import React from 'react';
import {Link} from 'react-router-dom';
import { CopyrightBarcz } from "./CopyrightBarcz";
import { NavBarcz } from "./NavBarcz";

export const Evacz = () => (
    <div>
    <div id="backgroundImg" className="">
        <section className="main" id="eva">

            <NavBarcz/>

            <div className="container-fluid fullheight">
                <div className="row fullheight">

                    {/* <Link to={"/"}><p className="close" /></Link> */}

                    <div className="col-lg-9">
                        <div className="letter">

                                <div className="headline txtcenter">
                                    <h1>PhDr. Ing. Eva Benešová</h1>
                                    <hr className="w" />
                                        <p className="small-text"><i>- Kouč certifikovaný Marshallem Goldsmithem 
                                            <br/>- Philippe Rosinski Mezikulturní a Globální koučování 
                                            <br/>- Exekutivní koučink a Exekutivní rozvoj</i></p>
                                </div>

<img className="top-img" src={require('../img/1.png')} />
                            <p className="small-text1">„Cítím se jako máslo slabě rozetřené na velký krajíc chleba,“ řekl mi jeden klient během 
                            našeho prvního setkání. Musím zdůraznit, že se jednalo o klienta, který se zdál úspěšný po všech pozorovatelných stránkách: kariéra, 
                            osobní život, rodina, rodičovství i vztahy – a na první pohled působil spokojeně. Měl všechno, co mohl žádat, svých životních cílů 
                            dosáhl už ve dvaačtyřiceti letech a vypadalo to, že si nemůže na nic stěžovat. Až na určitý vnitřní pocit, jenž ho ke mně přivedl.
                            </p>

                            <p><b>Museli jste se někdy vypořádat s takovou situací, kdy jste se cítili podobně? Měli jste někdy pocit, že navzdory celkově úspěšnému
                             životu se uvnitř vás něco hromadí – a vy musíte udělat něco důležitého a zásadního, abyste se toho zbavili? 
                                <br/>
                                <i>Uklidněte se. Nejste v tom sami.</i>
                            </b></p>

                            <div className="aa">
                            <ul>
                                <li>Chcete objevit nové možnosti, pojmenovat svoje priority a posunout se k novým perspektivám? </li>
                                <li>Chcete se přestat pohybovat v kruzích a zjistit, co vám skutečně vyhovuje?</li>
                                <li>Chcete pro sebe více volného času?</li>
                                <li>Chcete překonat odpor k rychlým změnám a snížit své ztráty, abyste v životě znovu dosáhli štěstí?</li>
                                <li>Chcete zjistit, jaké přesné změny je třeba udělat, abyste se dramaticky pohnuli kupředu?</li>
                                <li>Chcete pomocí významné změny získat vnitřní sílu a energii a posunout svůj život na další úroveň?</li>
                                <li><b>Chcete přestat stagnovat a začít si věřit?</b></li>
                            </ul>
                            </div><br/>

                            <p>Již více než dvacet let s nadšením pracuji s lidmi, abych jim byla oporou během různých změn v jejich životě a 
                            pomohla jim překonat nechtěné či nevítané zlozvyky. Ještě větší nadšení ve mně však vzbuzuje rozvíjení silných stránek 
                            jejich osobností, sociálních a manažerských dovedností. Používám především metody exekutivního psychologického koučinku 
                            a funguji jako klientův partner pro hluboké rozhovory a sebereflexi. 
                            </p>

                            <p>Už v deseti letech jsem věděla, že chci pracovat s individuálními klienty, a od té doby jsem veškerou svoji energii 
                            směřovala k tomuto cíli. Svoji práci si nesmírně užívám a jsem šťastná, když můžu přispět k pozitivní změně v životě svých klientů. 
                            Mým typickým klientem je člověk na vysoké manažerské pozici či majitel společnosti, členové jejich týmů a talentovaní jedinci, 
                            kteří se chtějí posunout dál.
                            </p>

                            <h3 className="mt-5"><b>Co můžete ode mě a Principal Coaching očekávat?</b></h3>

                            <ol>
                                <li>Mám rozsáhlé mezinárodní znalosti. Většina mých klientů ke mně přichází s doporučením. </li>
                                <li>Koučuji úspěšné jedince na C-level pozicích a vytvářím jim individuální rozvojové programy.</li>
                                Pořádám osobní, a pokud je třeba i skupinová sezení.
                                <li>Připravuji týmové workshopy, retreaty a experimentální programy ušité dané skupině na míru, založené na individuálním 
                                osobním profilu účastníků a potřebách týmu.</li>
                                <li>Jako certifikovaná psycholožka provádím psychologické a kariérní hodnocení osobnosti – pro tento účel používám ty 
                                nejpropracovanější a nejpodrobnější dotazníky dostupné pro zdravé jedince v manžerské populaci.</li>
                                <li>Jako součást Bright Principal© organizuji workshopy s účastí inspirativních mezinárodních řečníků, které si zvu pro 
                                svoje korporátní klienty v České republice. S velkým úspěchem  v Čechách organizuji také jedno či vícedenní workshopy s 
                                Marhallem Goldsmithem, Johnem Mattone, Philippe Rosinski, Willem Linssenem, Srikumarem S. Raem a Alem Moscardelli. 
                                Při třech příležitostech jsem spolupracovala na projektech konajících se v Praze, jež měly za úkol představit Deepaka Chopru 
                                zemím střední a východní Evropy (CEE region). </li>
                                <li>Jako součást programu Leadership Rise© spojuji C-level mentory s jejich C-level klienty.</li>

                            </ol>

                                    <h3 className="mt-5"><b>Dosavadní činnost a zkušenosti</b></h3>

                                    <p>Dvanáct let jsem žila ve Velké Británii. V roce 2006 jsem se pustila do budování svoji první exekutivní psychologické 
                                    koučovací praxe s názvem Principal Coaching. Pracuji v Praze a moje klienty najdete v různých zemích na mnoha kontinentech. </p>

                            <p>V roce 2007 jsem potkala <b>Philippe Roskinski</b>, nejváženější postavu v oblasti globálního koučinku a koučinku napříč kulturami. 
                            Philippeho inovativní přístup spočívající v tom, že do vedení a koučování začlenil zásadní a často opomíjený mezikulturní rozměr, 
                            mu přinesl celosvětový věhlas. S Philippem si velmi dobře rozumíme a byla jsem to já, kdo představil jeho koncept koučinku v České republice 
                            a na Slovensku. Intenzivně s ním spolupracuji jako jeho společník pro CEE region. Stala jsem se prvním exekutivním koučem ve východní Evropě, 
                            který obdržel „Certifikát mezikulturního vedení a koučování – dotazník kulturních orientací“ („Certification of Leading and Coaching 
                            Across Culture – COF Assessment”), a používám Philippeho holistický, globální koncept koučinku. <b>Při práci se svými klienty využívám fyziologické, 
                            psychologické, manažerské, kulturní, vlivové a duchovní perspektivy.</b></p>

                            <div className="float-right quote-wrapper">
                                <div className="quote quote-right m-0 p-0" >
                                    <h4>"Nikdy není příliš <br/> pozdě stát se tím, <br/> kým byste <br/>mohli být."</h4>
                                    <h5 className="pink-text">George Elliot</h5>
                                </div>

                                <img src={require('../img/eva.jpg')} className="content-img eva-img ml-4"/>
                            </div>

                            <p>V roce 2014 jsem se seznámila s <b>Marshallem Goldsmithem</b>, celosvětovou osobností číslo jedna v kategorii Leadership Thinker, 
                            vítězem ceny Executive Coach Thinkers50, a stala se prvním člověkem v České republice a třetím ve východní Evropě, který získal tři z 
                            jeho certifikací. Stala jsem se jeho Associate a začala propagovat jeho <b>měřitelný koučovací přístup soustředěný na zúčastněné osoby</b> 
                            v České republice a na Slovensku. Marshall patří mezi moje největší osobní i profesionální 
                            inspirace a setkání s ním změnilo můj život. Díky němu jsem se také setkala s <b>Johnem Mattonem</b>, osobním koučem Steva Jobse, a 
                            začala s ním spolupracovat také s ním. V roce 2019 jsem s velkým úspěchem poprvé představila v Praze a CEE regionu jeho <b>program 
                            Inteligentního leadership</b></p>


                            <p><i>Podrobná studie přezkoumávající více než jedenáct tisíc obchodních lídrů mezinárodních organizací  dospěla k závěru, že se u 95% lidí, 
                            kteří se zúčastnili Stakelholder Centered Coaching, měřitelně zvýšila efektivita leadershipu (zveřejněno v článku 
                                Leadership je kontaktní sport, Strategy and Business, září 2004).</i></p>

                            <p>Jsem doktorka psychologie a inženýrka ekonomie. Studovala jsem také skupinovou analýzu na Institute of Group Analysis v Londýně. 
                            Kromě <b>Marshalla Goldsmithe</b> a <b>Philippe Rosinski</b> jsem získala několik mezinárodně uznávaných certifikátů pro koučování od 
                            světově známých jmen jako jsou <b>John Whitmore</b>, <b>Timothy Gallwey</b>, <b>Marilyn Atkinson</b> a <b>Will Linssen</b>. Působila 
                            jsem jako asistentka na Katedře manažerské psychologie a sociologie na Vysoké škole ekonomické v Praze a byla jsem spoluzakladatelkou 
                            dvou společností zaměřujících se rozvoj společenských a manažerských dovedností. Ve svém doktorském výzkumu jsem se zabývala absolventy 
                            magisterských ekonomických oborů (MBA) na různých pozicích v několika investičních bankách v City of London.</p>

                            <p>Ovlivňuje mě <b>Deepak Chopra</b>, moderní neurověda, východní filozofie a psychologie. Intenzivně si uvědomuji význam výzkumu kvantové 
                            fyziky a možnosti hloubky duchovního vývoje. </p>

                            <p>Mezi moje nejváženější klienty patří: EY, LEGO EMEA Distribution, Deutsche Bank WM, Novo Nordisk Region Europe, Česká Spořitelna, 
                            Arriva SCEE, General Motors Europe, Savensia Fromage, Skoda Auto India, Zátiší Group, Hogan Lowels International, Sephora, ČSOB, 
                            Havas Worldwide New Europe, iPrice Group Malaysia, Partners Financial Services, PAN Solutions, a další.</p>

                            <p>Pokud vás to, co jste si právě přečetli, zaujalo, přejete si na sobě zapracovat a změnit nepříjemné stereotypy vašeho života, 
                            přejete si být spokojení a maximálně efektivní, nebo hledáte pro sebe a svůj tým exekutivního kouče, odhodlejte se k prvnímu kroku 
                            a napište mi. Jsem ten správný člověk, který vám může pomoct probudit vaši vnitřní pozitivní energii a budu vaším průvodcem při hledání jasné,
                             efektivní a funkční cesty životem.
                            </p>

                            <p>Učiňte tento krok pro sebe, než se vaše pocity rozvinou v něco, co začne ovlivňovat celkovou rovnováhu vašeho pečlivě vybudovaného světa. 
                            Napište mi, než se rozhodnete vzít si “pilulku štěstí”. 
                            </p>

                        </div>

                                <div className="row">
                                    <div className="col-12">

                                        <div className="letter">
                                <h5 className="mt-3 mb-3">Těším se na naši spolupráci.</h5>
                                        </div>
                                        </div>
                    </div>
<br/>
                    </div>
                    <div className="col-lg-3 fixedNav">
                        <div className="nav desktop-nav">
                            <div className="nav-content">
                                <Link to={"/evacz"}><h2>Evy Benešové</h2></Link>
                                <hr className="h-line pink" />
                                <br />
                                <Link to={"/evacz"}> <h3 className="pink-text">O Evě </h3></Link>
                                 <br />
                                 <Link to={"/interviewcz"}> <h3>Rozhovor</h3></Link>
                            </div>
                        </div>
                    </div>
                    </div>


            </div>
        </section>
    </div>
        <CopyrightBarcz/>
    </div>
);
