import React, { Component, useRef } from 'react';
import {Link} from 'react-router-dom';
import ProgramData from '../programs.json';
import PropTypes from 'prop-types';
import {ExecNavcz} from "./ExecNavcz";
import { CopyrightBarcz } from "./CopyrightBarcz";
import { NavBarWhitecz } from "./NavBarWhitecz";
import ModalVideo from 'react-modal-video';



export class Mattonecz extends React.Component {

    constructor () {
        super()
        this.state = {
            isOpen1: false,
            isOpen2: false
        }
        this.openModal1 = this.openModal1.bind(this);
        this.openModal2 = this.openModal2.bind(this);
    }

    openModal1 () {
        this.setState({isOpen1: true})
    }
    openModal2 () {
        this.setState({isOpen2: true})
    }


    render () {

        return (

            <div>
            <section className="main">

                <NavBarWhitecz/>
<br/>
                <div className="container-fluid fullheight">
                    <div className="row fullheight">

                        <div className="col-lg-9" id="speaker-bg">


                            <div className="speaker-list mt-5">

                                <div className="row">
                                    <div className="col-md-5 text-center">
                                        <img src={require("../img/mattone-pic.png")}
                                             className="img-fluid p-3 w-75 m-auto"/>
                                        <img src={require("../img/mattone2.jpg")}
                                             className="img-fluid p-3 w-75 m-auto"/>
                                    </div>


                                    <div className="col-md-7">

                                        <div className="vertical-center">

                                            <div className="headline pb-5 pt-5 m-0">
                                                {/* {ProgramData.map((programDetail, index) => {
                                        console.log(programDetail);
                                        return <h1>{programDetail.speaker}</h1>
                                    })} */}

                                                <h1>{ProgramData[1].speaker}</h1>

                                                <hr className="hr-headline"/>
                                            </div>

                                            <p>John Mattone je nejlépe prodávaný autor, světově uznávaná osobnost č. 1 v oblasti Intelligent Leadership 
                                            a jeden z nejžádanějších přednášejících na téma “Koučink a leadership CEO” na světě. Od roku 2017 se podle Globalgurus.org 
                                            řadí spolu s Tonym Robbinsem a Marhallem Goldsmithem mezi tři největší světové osobnosti koučinku a mezi 10 světových osobností 
                                            a řečníků v oblasti leadershipu. V roce 2017 se John stal jedním z finalistů prestižní ceny Thinkers50 Leadership Award, která je 
                                            udělována největší světové osobnosti a mysliteli v oblasti leadershipu. John Mattone je bývalým exekutivním koučem Steva Jobse a 
                                            bývalého legendárního CEO společnosti PepsiCo, Rogera Enrica.</p>

                                            <p>Johne Mattone představil svůj program “Intelligent Leadership” v Praze, 24. dubna 2019</p>

                                            <ul className="program-list">
                                                <li><a href="http://www.johnmattone.cz" target="_blank"><h6
                                                    className="mb-4">www.johnmattone.cz</h6></a></li>
                                            </ul>


                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 video-container mt-5">
                                        <div className="video-list">

                                            <h1>Video Přehrávač</h1>

                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen1} videoId='xleBtnrnm-k' onClose={() => this.setState({isOpen1: false})} />
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen2} videoId='fEy3eSw9U2Y' onClose={() => this.setState({isOpen2: false})} />

                                            <div id="carouselExampleControls" className="carousel slide desktop-carousel" data-ride="carousel">
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active">
                                                        <div className="video-item" onClick={this.openModal1}>
                                                            <img src="https://img.youtube.com/vi/xleBtnrnm-k/0.jpg"/>
                                                            <p className="small-text">Steve Jobs’ coach shares tips for passionate leadership - John Mattone</p>
                                                        </div>

                                                        <div className="video-item" onClick={this.openModal2}>
                                                            <img src="https://img.youtube.com/vi/fEy3eSw9U2Y/0.jpg"/>
                                                            <p className="small-text">John Mattone - Can Do, Will Do, Must Do!</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                    <span className="sr-only">Next</span>
                                                </a>

                                            </div>

                                            <div id="carouselExampleControls2" className="carousel slide mobile-carousel" data-ride="carousel">
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active">
                                                        <div className="video-item" onClick={this.openModal1}>
                                                            <img src="https://img.youtube.com/vi/xleBtnrnm-k/0.jpg"/>
                                                            <p className="small-text">Steve Jobs’ coach shares tips for passionate leadership - John Mattone</p>
                                                        </div>
                                                    </div>
                                                    <div className="carousel-item">
                                                        <div className="video-item" onClick={this.openModal2}>
                                                            <img src="https://img.youtube.com/vi/fEy3eSw9U2Y/0.jpg"/>
                                                            <p className="small-text">John Mattone - Can Do, Will Do, Must Do!</p>
                                                        </div>
                                                    </div>
                                                    </div>

                                                <a className="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                                <a className="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                    <span className="sr-only">Next</span>
                                                </a>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/*
                                <div className="row mt-5 pt-5">
                                    <div className="col-12 videoBg text-center">
                                        <iframe className="m-3 video" src="https://www.youtube.com/embed/xleBtnrnm-k" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                                        <iframe className="m-3 video" src="https://api.play-backend.iprima.cz/prehravac/embedded?id=p560603" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        <iframe className="m-3 video" src="https://api.play-backend.iprima.cz/prehravac/embedded?id=p562255" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                    </div>
                                </div> */}


                        <ExecNavcz/>
                    </div>
                </div>


            </section><br/><br/><br/><br/>
                <CopyrightBarcz/>
            </div>
        );
    }
}

