import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import Popper from 'popper.js';


export const ExecNav = () => {

    {/* $(".nav-link").on("click", function(){
        console.log("toimii");
        $(".navbar").find(".active").removeClass("active");
        $(this).addClass("active");
    }); */}

    return (

        <React.Fragment>

            <div className="col-lg-3 fixedNav bright-nav">
                <div className="nav desktop-nav">
                    <div className="nav-content">
                        <Link to={"/brightprincipal"}><h2>Bright Principal<span className="small-r">®</span></h2></Link>
                        <hr className="h-line pink" />
                        <br />
                        <Link to={"/executiveprograms"}> <h3 className="pink-text">Executive Development<br/> Programs</h3></Link>
                        <br />
                        <Link to={"/mentoring"}> <h3>Leadership Rise<span className="small-r">®</span> Mentoring</h3></Link>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
};