import React, { Component, useRef } from 'react';
import {Link} from 'react-router-dom';
import ProgramData from '../programs.json';
import PropTypes from 'prop-types';
import {ExecNavcz} from "./ExecNavcz";
import { CopyrightBarcz } from "./CopyrightBarcz";
import { NavBarWhitecz } from "./NavBarWhitecz";
import ModalVideo from 'react-modal-video'



export class Chopracz extends React.Component {

    constructor () {
        super()
        this.state = {
            isOpen1: false,
            isOpen2: false
        }
        this.openModal1 = this.openModal1.bind(this);
        this.openModal2 = this.openModal2.bind(this);
    }

    openModal1 () {
        this.setState({isOpen1: true})
    }
    openModal2 () {
        this.setState({isOpen2: true})
    }


    render () {

        return (

            <div>
            <div id="backgroundImg">
                <section className="main">

                    <NavBarWhitecz/>

                    <div className="container-fluid fullheight">
                        <div className="row fullheight">

                            <div className="col-lg-9" id="speaker-bg">


                                <div className="speaker-list mt-5 pt-5">

                                    <div className="row">
                                        <div className="col-md-5"><br/><br/>
                                            <img src={require("../img/eva-deepakchopra.JPG")}
                                                 className="img-fluid p-3"/>
                                        </div>


                                        <div className="col-md-7">

                                            <div className="vertical-center">

                                                <div className="headline pb-5 m-0">
                                                    {/* {ProgramData.map((programDetail, index) => {
                                        console.log(programDetail);
                                        return <h1>{programDetail.speaker}</h1>
                                    })} */}

                                                    <h1>{ProgramData[2].speaker}</h1>

                                                    <hr className="hr-headline"/>
                                                </div>

                                                <p>Deepak Chopra, odborník v oblasti uzdravování mysli a těla, je světově proslulý řečník 
                                                a autor na téma alternativní medicíny.  <br/><br/>
                                                    Bright Principal ve spolupráci s Evolution Hub měl tu čest přivítat Deepaka Chopru v Praze již třikrát.</p>

                                                <ul className="program-list">
                                                    <li><p
                                                        className="mb-4">{ProgramData[2].programs.first.title}</p>
                                                    </li>
                                                    <li><p
                                                        className="mb-4">{ProgramData[2].programs.second.title}</p>
                                                    </li>
                                                    <li><p
                                                        className="mb-4">{ProgramData[2].programs.third.title}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
<br/>
                                    <div className="row">
                                        <div className="col-12 video-container mt-5">
                                            <div className="video-list">

                                                <h1>Video Přehrávač</h1>

                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen1} videoId='9wC8Ufb2kGM' onClose={() => this.setState({isOpen1: false})} />
                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen2} videoId='kVnT7nF2twM' onClose={() => this.setState({isOpen2: false})} />

                                                <div id="carouselExampleControls" className="carousel slide desktop-carousel" data-ride="carousel">
                                                    <div className="carousel-inner">
                                                        <div className="carousel-item active">
                                                            <div className="video-item" onClick={this.openModal1}>
                                                                <img src="https://img.youtube.com/vi/9wC8Ufb2kGM/0.jpg"/>
                                                                <p className="small-text">Finding your True Self, the Cure for all Suffering - Deepak Chopra</p>
                                                            </div>

                                                            <div className="video-item" onClick={this.openModal2}>
                                                                <img src="https://img.youtube.com/vi/kVnT7nF2twM/0.jpg"/>
                                                                <p className="small-text">Deepak Chopra: ON How To Be More Present & Not Be Overwhelmed With Life</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Previous</span>
                                                    </a>
                                                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Next</span>
                                                    </a>

                                                </div>

                                                <div id="carouselExampleControls2" className="carousel slide mobile-carousel" data-ride="carousel">
                                                    <div className="carousel-inner">
                                                        <div className="carousel-item active">
                                                            <div className="video-item" onClick={this.openModal1}>
                                                                <img src="https://img.youtube.com/vi/9wC8Ufb2kGM/0.jpg"/>
                                                                <p className="small-text">Finding your True Self, the Cure for all Suffering - Deepak Chopra</p>
                                                            </div>
                                                        </div>
                                                        <div className="carousel-item">
                                                            <div className="video-item" onClick={this.openModal2}>
                                                                <img src="https://img.youtube.com/vi/kVnT7nF2twM/0.jpg"/>
                                                                <p className="small-text">Deepak Chopra: ON How To Be More Present & Not Be Overwhelmed With Life</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <a className="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Previous</span>
                                                    </a>
                                                    <a className="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Next</span>
                                                    </a>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <ExecNavcz/>
                        </div>
                    </div>

                </section>

            </div><br/><br/><br/>
                <CopyrightBarcz/>
            </div>
        );
    }
}

