import React, { Component, useRef } from 'react';
import {Link} from 'react-router-dom';
import ProgramData from '../programs.json';
import PropTypes from 'prop-types';
import {ExecNavcz} from "./ExecNavcz";
import { CopyrightBarcz } from "./CopyrightBarcz";
import { NavBarWhitecz } from "./NavBarWhitecz";
import ModalVideo from 'react-modal-video';


export class Linsencz extends React.Component {

    constructor () {
        super()
        this.state = {
            isOpen1: false,
            isOpen2: false
        }
        this.openModal1 = this.openModal1.bind(this);
        this.openModal2 = this.openModal2.bind(this);
    }

    openModal1 () {
        this.setState({isOpen1: true})
    }
    openModal2 () {
        this.setState({isOpen2: true})
    }

    render () {

        return (

            <div>
            <div id="backgroundImg">
                <section className="main">

                    <NavBarWhitecz/>
<br/>
<p class="mobile-only"><br/><br/><br/></p>
                    <div className="container-fluid fullheight">
                        <div className="row fullheight">

                            <div className="col-lg-9" id="speaker-bg">


                                <div className="speaker-list mt-5">

                                    <div className="row">
                                        <div className="col-md-5 e">
                                            <img src={require("../img/linssen3.png")} className="img-fluid p-3 vertical-center"/>
                                        </div>


                                        <div className="col-md-7">

                                            <div>

                                                <div className="headline pb-5 m-0">
                                                    {/* {ProgramData.map((programDetail, index) => {
                                        console.log(programDetail);
                                        return <h1>{programDetail.speaker}</h1>
                                    })} */}

                                                    <h1>{ProgramData[4].speaker}</h1>

                                                    <hr className="hr-headline"/>
                                                </div>
                                                <p>Will je známý jako "kouč koučů" a je jedním z prvních master koučů certifikovaných Marshallem Goldsmithem
                                                 v Stakeholder Centered Coaching. Více než dvě desetiletí pracuje s exekutivními týmy na měřitelném zlepšení 
                                                 jejich leadershipu a organizační efektivnosti. Zastává pozice v obecném a podnikovém managementu v nadnárodních 
                                                 společnostech v Evropě a v Asii a také zasedá v radách několika nadnárodních společností v Asii. Mimoto cestuje 
                                                 po světě a pomocí Mashallovy metodologie školí exekutivní kouče a byznys lídry.</p>

                                                <p><br/>Will uspořádal dva skvělé pražské workshopy pro komunitu Americké Obchodní komory v České republice:</p>

                                                    <ul className="program-list">
                                                        <li><p
                                                            className="mb-4">Measurably Growing the Talent Pool in Your Organization, March
                                                            10, 2014</p>
                                                        </li>
                                                        <li><p
                                                            className="mb-4">Developing Leaders in Real Time, March 11, 2014</p>
                                                        </li>
                                                    </ul>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 video-container mt-5">
                                            <div className="video-list">

                                                <h1>Video Přehrávač</h1>

                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen1} videoId='Ru2AuTqzavc' onClose={() => this.setState({isOpen1: false})} />
                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen2} videoId='Ulmc6ZqYLXA' onClose={() => this.setState({isOpen2: false})} />

                                                <div id="carouselExampleControls" className="carousel slide desktop-carousel" data-ride="carousel">
                                                    <div className="carousel-inner">
                                                        <div className="carousel-item active">
                                                            <div className="video-item" onClick={this.openModal1}>
                                                                <img src="https://img.youtube.com/vi/Ru2AuTqzavc/0.jpg"/>
                                                                <p className="small-text">Will Linssen describes Marshall Goldsmith Stakeholder Centered Coaching</p>
                                                            </div>

                                                            <div className="video-item" onClick={this.openModal2}>
                                                                <img src="https://img.youtube.com/vi/Ulmc6ZqYLXA/0.jpg"/>
                                                                <p className="small-text">World Coaching Congress 2016 - Will Linssen</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Previous</span>
                                                    </a>
                                                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Next</span>
                                                    </a>

                                                </div>

                                                <div id="carouselExampleControls2" className="carousel slide mobile-carousel" data-ride="carousel">
                                                    <div className="carousel-inner">
                                                        <div className="carousel-item active">
                                                            <div className="video-item" onClick={this.openModal1}>
                                                                <img src="https://img.youtube.com/vi/Ru2AuTqzavc/0.jpg"/>
                                                                <p className="small-text">Will Linssen describes Marshall Goldsmith Stakeholder Centered Coaching</p>
                                                            </div>
                                                        </div>
                                                        <div className="carousel-item">
                                                            <div className="video-item" onClick={this.openModal2}>
                                                                <img src="https://img.youtube.com/vi/Ulmc6ZqYLXA/0.jpg"/>
                                                                <p className="small-text">World Coaching Congress 2016 - Will Linssen</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <a className="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Previous</span>
                                                    </a>
                                                    <a className="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                        <span className="sr-only">Next</span>
                                                    </a>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <ExecNavcz/>

                        </div>
                    </div>

                </section>

            </div><br/><br/><br/>
                <CopyrightBarcz/>
            </div>
        );
    }
}

