import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import Popper from 'popper.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const CopyrightBarcz = () => {

    return (

        <React.Fragment>

            <div className="container-fluid copy-bar">
                <div className="row">
                    <div className="col-md-4 col-12">
                        <p className="small-text4">©2020 - Principal Coaching. All Rights Reserved</p>
                    </div>
                    <div className="col-md-4 text-center col-12 hide-mobile">
                        <a href="https://www.linkedin.com/in/eva-benesova-0297075/" target="_blank"><FontAwesomeIcon icon={['fab', 'linkedin']} style={{fontSize: 22 + "px"}}/></a>
                    </div>
                    <div className="col-md-4 col-12 hide-mobile">
                        <p className="float-right small-text">Designed and Developed by <a target="_blank" href="http://www.uxsolutions.cz">UX Solutions</a></p>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
};