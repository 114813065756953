import React, { Component } from 'react';
import './App.css';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import {Home} from './components/Home';
import {Domu} from './components/Domu';
import {Eva} from './components/Eva';
import {Evacz} from './components/Evacz';
import {Bright} from './components/Bright';
import {Brightcz} from './components/Brightcz';
import {NoMatch} from './components/NoMatch';
import {Executive} from "./components/Executive";
import {Executivecz} from "./components/Executivecz";
import {Interview} from "./components/Interview";
import {Interviewcz} from "./components/Interviewcz";
import {Mentoring} from "./components/Mentoring";
import {Mentoringcz} from "./components/Mentoringcz";
import {Contact} from "./components/Contact";
import {Kontakt} from "./components/Kontakt";
import {Details} from "./components/Details";
import {NavBarWhite} from "./components/NavBarWhite";
import {NavBarWhitecz} from "./components/NavBarWhitecz";
import {CopyrightBar} from "./components/CopyrightBar";
import {CopyrightBarcz} from "./components/CopyrightBarcz";
import {Goldsmith} from "./components/Goldsmith";
import {Goldsmithcz} from "./components/Goldsmithcz";
import {Mattone} from "./components/Mattone";
import {Mattonecz} from "./components/Mattonecz";
import {Linsen} from "./components/Linsen";
import {Linsencz} from "./components/Linsencz";
import {Rosinski} from "./components/Rosinski";
import {Rosinskicz} from "./components/Rosinskicz";
import {Chopra} from "./components/Chopra";
import {Chopracz} from "./components/Chopracz";
import {Rao} from "./components/Rao";
import {Raocz} from "./components/Raocz";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin} from '@fortawesome/free-brands-svg-icons';

library.add(fab, faLinkedin);


function App() {

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path={"/"} component={Home} />
            <Route path={"/eva"} component={Eva} />
            <Route path={"/brightprincipal"} component={Bright} />
            <Route path={"/executiveprograms"} component={Executive} />
            <Route path={"/interview"} component={Interview} />
            <Route path={"/mentoring"} component={Mentoring} />
            <Route path={"/contact"} component={Contact} />
            <Route path={"/details"} component={Details} />
            <Route path={"/goldsmith"} component={Goldsmith} />
            <Route path={"/mattone"} component={Mattone} />
            <Route path={"/linsen"} component={Linsen} />
            <Route path={"/rosinski"} component={Rosinski} />
            <Route path={"/chopra"} component={Chopra} />
            <Route path={"/rao"} component={Rao} />
            <Route path={"/domu"} component={Domu} />
            <Route path={"/evacz"} component={Evacz} />
            <Route path={"/brightprincipalcz"} component={Brightcz} />
            <Route path={"/executiveprogramscz"} component={Executivecz} />
            <Route path={"/interviewcz"} component={Interviewcz} />
            <Route path={"/Mentoringcz"} component={Mentoringcz} />
            <Route path={"/kontakt"} component={Kontakt} />
            <Route path={"/goldsmithcz"} component={Goldsmithcz} />
            <Route path={"/mattonecz"} component={Mattonecz} />
            <Route path={"/linsencz"} component={Linsencz} />
            <Route path={"/rosinskicz"} component={Rosinskicz} />
            <Route path={"/chopracz"} component={Chopracz} />
            <Route path={"/raocz"} component={Raocz} />
            <Route component={NoMatch} />
        </Switch>
      </Router>

    </React.Fragment>
  );
}

export default App;
