import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import Popper from 'popper.js';


export const NavBar = () => {

    {/* $(".nav-link").on("click", function(){
        console.log("toimii");
        $(".navbar").find(".active").removeClass("active");
        $(this).addClass("active");
    }); */}

    return (

        <React.Fragment>


            <nav className="navbar navbar-expand-lg navbar-light nav-black">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to={"/"}><img src={require('../img/logo3.png')} className="logo main-img float-left" style={{width: 160 + "px"}}/></Link>
                        </li>
                        <li className="nav-item active">
                            <a className="nav-link text-uppercase" href="#"><NavLink to="/">Home<span class="sr-only">(current)</span></NavLink></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-uppercase" href="#"><NavLink to="/eva" activeClassName="selectedLink">About Eva</NavLink></a>
                        </li>
                        <li className="nav-item nav-item-mobile">
                            <a className="nav-link text-uppercase" href="#"><NavLink to="/interview" activeClassName="selectedLink">Eva's Interview</NavLink></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-uppercase" href="#"><NavLink to="/brightprincipal" activeClassName="selectedLink">Bright Principal</NavLink></a>
                        </li>
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <Link to={"brightprincipal"}>Bright Principal</Link>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="#"><Link to={"executiveprograms"}>Executive Development Programs</Link></a>
                                <a className="dropdown-item" href="#"><Link to={"mentoring"}>Leadership Rise Mentoring</Link></a>
                            </div>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link text-uppercase" href="#"><NavLink to="/contact" activeClassName="selectedLink">Contact</NavLink></a>

                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-uppercase" href="#"><Link to="/Domu" activeClassName="selectedLink"><img src={require("../img/czflag.png")} className="flag"/></Link></a>

                        </li>
                    </ul>

                </div>
            </nav>
            <p class="mobile-only"><br/></p>



        </React.Fragment>
    )
};