import React from 'react'
import {Link} from 'react-router-dom';
import { CopyrightBar } from "./CopyrightBar";
import { NavBarWhite } from "./NavBarWhite";



export const Home = () => (
    <div>
    <div className="still-footer">
    <div id="backgroundImg-home">
        <section className="main">

            <NavBarWhite/>

            <div className="container-fluid fullheight">
                <div className="row fullheight">
                    <div className="col-12">

                        <div className="home-text" style={{color: "white"}}>
                            <h1 className="principal">Welcome to Principal Coaching</h1>
                            <hr className="hr-landing f" style={{borderColor: "white"}}/>
                            <h1 className="coaching">By Eva Benesova</h1>
                        </div>


                        {/*<img src={require('../img/logo.png')} className="main-img mb-5 pb-4 float-right" style={{width: 200 + "px"}}/>*/}

                    </div>
                </div>
            </div>

        </section>

    </div>

    </div>
    
    <CopyrightBar/>
    </div>
);
