import React from 'react';
import {Link} from 'react-router-dom';
import { NavBarWhite } from "./NavBarWhite";
import { CopyrightBar } from "./CopyrightBar";

export const Bright = () => (
    <div className="still-footer">
        <section className="main">

            <NavBarWhite/>

            <div className="container-fluid fullheight">
                <div className="row fullheight">

                    <div className="col-lg-9 brightprincipal" id="bright-bg">

                        <div className="info-wrapper" style={{color: "white"}}>

                            <div className="headline">
                                <h1>Bright Principal<span className="r">®</span></h1>
                                <hr className="hr-headline"/>
                            </div>

                            <p>In Bright Principal I enjoy inviting and hosting global, inspirational thinkers to the region of the Czech Republic
                                to share their wisdom with like-minded individuals who are passionate about bringing more meaning into their lives and hearts. </p>
                            <p>I am very privileged that I’ve been able to contribute my work by, for example, introducing Marshall Goldsmith,
                                John Mattone, Deepak Chopra, Philippe Rosinski, Srikumar S. Rao, Will Linssen and Al Moscardelli to the region of CEE.
                                I organize sessions, workshops, and retreats for leaders and/or for the purposes of talent-development.</p>
                            <div className="mobile-links">
                            <Link to={"/executiveprograms"}> <h3>Executive Development<br/> Programs</h3></Link>
                            <br />
                            <Link to={"/mentoring"}> <h3>Leadership Rise<span className="small-r">®</span> Mentoring</h3></Link>
                            </div>

                        </div>


                    </div>
                    <div className="col-lg-3 fixedNav bright-nav">
                        <div className="nav desktop-nav">
                            <div className="nav-content">
                                <Link to={"/brightprincipal"}><h2>Bright Principal<span className="small-r">®</span></h2></Link>
                                <hr className="h-line pink" />
                                <br />
                                <Link to={"/executiveprograms"}> <h3>Executive Development<br/> Programs</h3></Link>
                                <br />
                                <Link to={"/mentoring"}> <h3>Leadership Rise<span className="small-r">®</span> Mentoring</h3></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <CopyrightBar/>
    </div>

);
