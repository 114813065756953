import React from 'react'
import {Link} from 'react-router-dom';
import { NavBarWhite } from "./NavBarWhite";
import { CopyrightBar } from "./CopyrightBar";


export const Contact = () => (

    <div id="contact-bg">
        <section className="main" id="contact">
            <NavBarWhite />
            <div className="container-fluid fullheight">


                <div className="row fullheight">
                    <div className="col-md-8">

                        <div className="vertical-center" style={{width: 100 + "%"}}>
                            <h1 className="principal">Dr. Eva Benesova</h1>

                            <div className="row">

                            <div className="col-md-5">
                                <form className="contactform vertical-center" style={{width: 90 + "%"}} method="post">
                                    <div className="form-group">
                                        <input type="text" name="name" className="form-control" placeholder="Name"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" name="mail" className="form-control" placeholder="Email Address"/>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" name="message" id="exampleFormControlTextarea1" rows="3" placeholder="Your Message"/>
                                    </div>
                                    <button type="submit" name="submit" className="btn btn-primary submitBtn" style={{float: "right", borderWidth: 1 + "px"}}>Send</button>
                                </form>
                            </div>

                            <div className="col-md-7" style={{color: "white"}}>

                                <div id="prague">
                                    <h3><b>Czech Republic</b></h3>
                                    <p className="address">Liliová 248/8<br/>
                                        Prague - Old Town, 110 00<br/>
                                        Czech Republic</p>
                                </div>

                                
                                <div>
                                    <h3><b>Phone:</b></h3>
                                    <p className="address">+420 602 571 944<br/>
                                        </p>
                                </div>
                                <div>
                                    <h3><b>Email:</b></h3>
                                    <p className="address">eva@principalcoaching.com</p>
                                </div>
                            </div>
<p class="mobile-only"> <br/><br/>a<br/></p>
                            </div>
                        </div>

                        {/*

                        <div className="locations" id="prague">
                        <h5 className="pink-text"><b>Prague</b></h5>
                        <p className="address">Liliová 248/8<br/>
                        Prague - Old Town<br/>
                        110 00</p>
                        </div>
                        */}
                    </div>
                </div>
            </div>
            <CopyrightBar/>
        </section>
    </div>
);
